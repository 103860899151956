/* eslint-disable no-lonely-if */
import { AnyAction } from '@reduxjs/toolkit'
import { SplitType } from 'enums/split-type'
import { setPaymentInProcess } from 'features/paymentProgress/paymentProgress'
import { setSplitDetail } from 'features/tab/tab'
import { ITabDetail } from 'interfaces/tab'
import _ from 'lodash'
import { Dispatch } from 'react'
import { NavigateFunction } from 'react-router-dom'
import { toast } from 'react-toastify'
import guestService from 'services/guest.service'
import tabService from 'services/tab.service'
import trackingService from 'services/tracking.service'

interface handlePaymentClickProps {
  dispatch: Dispatch<AnyAction>
  navigate: NavigateFunction
  rawTabsDeatil: string
  tabDetails: ITabDetail
  isFromChange: boolean
  setOpenOrderDiffALertDialog?: React.Dispatch<React.SetStateAction<boolean>>
  test?: () => void
}
export const handlePaymentClick = async (props: handlePaymentClickProps) => {
  const {
    dispatch,
    navigate,
    rawTabsDeatil,
    tabDetails,
    isFromChange,
    setOpenOrderDiffALertDialog,
    test,
  } = props
  // add tracking
  const trackPaymentModeSelected = async (selectedSplitType: number) => {
    if (selectedSplitType === 6) {
      sessionStorage.setItem('paymentModeId', '6')
    } else if (selectedSplitType === 5) {
      sessionStorage.setItem('paymentModeId', '5')
    } else if (selectedSplitType === 7) {
      sessionStorage.setItem('paymentModeId', '7')
    } else if (selectedSplitType === 9) {
      sessionStorage.setItem('paymentModeId', '9')
    } else if (selectedSplitType === 8) {
      sessionStorage.setItem('paymentModeId', '8')
    }
    await trackingService.trackPaymentModeSelected()
  }
  const convertToCheck = async () => {
    dispatch(
      setSplitDetail({
        splitType: SplitType.Check,
        isSplitDone: true,
        noOfGuest: 0,
      })
    )
    await trackPaymentModeSelected(SplitType.Check)
    await tabService.setSplitData(9)
    navigate('/payment')
  }

  const convertToFull = async () => {
    dispatch(
      setSplitDetail({
        splitType: SplitType.Full,
        isSplitDone: true,
        noOfGuest: 0,
      })
    )
    await trackPaymentModeSelected(SplitType.Full)
    await tabService.setSplitData(8)
    navigate('/payment')
  }

  try {
    dispatch(setPaymentInProcess(true))
    const tableId = Number(sessionStorage.getItem('posTableId'))
    const locationId = Number(sessionStorage.getItem('establishmentId'))
    const data1 = await tabService.getTabs({
      posTableId: tableId,
      locationId,
    })
    const response = data1.data.data
    response.orders.forEach((ele) => {
      ele.orderDate = null
      ele.closedAt = null
    })
    const currentTab = JSON.parse(rawTabsDeatil)
    currentTab.orders.forEach((ele) => {
      ele.orderDate = null
      ele.closedAt = null
    })
    currentTab?.tabPayments?.payments?.forEach((ele) => {
      ele.total = Number(ele.total.toFixed(2))
    })
    if (
      !_.isEqual(
        _.omit(response, [
          'paymentModeId',
          'tabStatus',
          'loginId',
          'noOfGuest',
          'noOfSplits',
          'isCheckChanged',
          'paymentApi',
        ]),
        _.omit(currentTab, [
          'paymentModeId',
          'tabStatus',
          'loginId',
          'noOfGuest',
          'noOfSplits',
          'isCheckChanged',
          'paymentApi',
        ])
      )
    ) {
      setOpenOrderDiffALertDialog(true)
    } else {
      if (response.paymentApi) {
        if (
          tabDetails?.tabPayments === null &&
          isFromChange &&
          !tabDetails.orders.some((x) => x.isSelected === false) &&
          !tabDetails?.tabPayments?.isPosPaid &&
          !tabDetails?.isServiceChargeAvailable
        ) {
          await convertToFull()
        } else if (
          tabDetails?.tabPayments?.isPosPaid ||
          tabDetails?.isCheckChanged ||
          tabDetails.orders.some((x) => x.isSelected === false) ||
          tabDetails?.isServiceChargeAvailable
        ) {
          await convertToCheck()
        } else {
          test()
        }
      } else {
        toast.error('Payment service is not enabled for this Location.')
      }
    }
    dispatch(setPaymentInProcess(false))
  } catch (exce) {
    guestService.addLog({ message: `guest issue in handle click ${exce}` })
    dispatch(setPaymentInProcess(false))
    setOpenOrderDiffALertDialog(true)
  }
}

export const handleDemoPaymentClick = (
  dispatch,
  navigate,
  demoTabDetailData
) => {
  dispatch(setPaymentInProcess(true))
  const data = demoTabDetailData
  data.orders.forEach((ele) => {
    ele.orderDate = null
    ele.closedAt = null
  })
  if (data.paymentApi) {
    sessionStorage.setItem('demoOrderItems', JSON.stringify(demoTabDetailData))
    sessionStorage.setItem('isDemoTabPage', 'true')
    navigate('/payment')
  } else {
    toast.error('Payment service is not enabled for this Location.')
  }
  dispatch(setPaymentInProcess(false))
}
