import Button from 'components/Button/Index'
import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import guestService from 'services/guest.service'

function GuestUnsubscribe() {
  const { campaignId } = useParams()
  const { email } = useParams()
  const { restaurantId } = useParams()
  const [unsubscribed, setUnsubscribed] = useState(false)
  // const decodedEmail = decodeURIComponent(email)

  function decode(encodedEmail) {
    const decodedEmail = atob(encodedEmail)
    return decodedEmail
  }

  const UnsubscribeUser = async () => {
    try {
      console.log('decodeNumber(Number(restaurantId))', decode(restaurantId))
      console.log('decodeNumber(Number(campaignId))', decode(campaignId))
      const response = await guestService.GuestUnsubscribe({
        restaurantId: Number(decode(restaurantId)),
        email: decode(email),
        campaignId: Number(decode(campaignId)),
      })

      if (response?.data?.data === 'success') {
        toast.success('You have been successfully unsubscribed.')
        setUnsubscribed(true)
      } else {
        toast.error('There was an error processing your unsubscribe request.')
        setUnsubscribed(false)
      }
    } catch (error) {
      await guestService.addLog({
        message: `Guest Unsubscribe Error: ${error}`,
      })
    }
  }

  return (
    <>
      <Helmet>
        <title>Unsubscribe | FasTab</title>
      </Helmet>
      {/* wrapper start */}
      <section className="wrapper error-page">
        {/* main-content start */}
        <section className="main-content error-message">
          <h2>We’ll Miss You!</h2>
          <p>{decode(email)}</p>
          {unsubscribed ? (
            <>
              <p>
                You’ve been successfully removed from our mailing list.
                <br />
                Thank you for being with us!
              </p>
            </>
          ) : (
            <>
              <p>
                If you’re sure you want to unsubscribe, simply click the button
                below. We hope to see you back someday!
              </p>
              <Button
                variant="contained"
                type="button"
                onClick={UnsubscribeUser}
                color="primary"
                title="Unsubscribe"
              >
                Unsubscribe
              </Button>
            </>
          )}
        </section>
        {/* main-content end */}
      </section>
      {/* wrapper end */}
    </>
  )
}

export default GuestUnsubscribe
