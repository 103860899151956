import { createSlice } from '@reduxjs/toolkit'

const loadingSlice = createSlice({
  name: 'isLoading',
  initialState: false,
  reducers: {
    setIsLoading: (state, action) => {
      state = action.payload
      return action.payload
    },
  },
})

export const { setIsLoading } = loadingSlice.actions
export const isloading = (state: { isLoading: boolean }) => state.isLoading
export default loadingSlice.reducer
