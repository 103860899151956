import { Dialog, DialogContent, DialogTitle } from '@mui/material'
import { Close } from 'assets/images'
import Button from 'components/Button/Index'
import Counter from 'components/Counter/Index'
import { SplitType } from 'enums/split-type'
import { restuarantData } from 'features/restaurant/restaurant'
import { setSplitDetail, splitDetail, tabDetail } from 'features/tab/tab'
import { IOrderDetail } from 'interfaces/tab'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DonutChart from './DonutPieChart'

interface dialogProps {
  open: boolean
  onClose?: React.MouseEventHandler<Element>
  closeCallback?: (
    event: SplitType,
    noOfGuest: number,
    noOfPayingGuest?: number
  ) => void
}

function SplitByGuestDialog(props: dialogProps) {
  const { open, onClose, closeCallback } = props
  const tabDetailData = useSelector(tabDetail)
  const [noOfGuest, setNoOfGuest] = useState(1)
  const [noOfPayingGuest, setNoOfPayingGuest] = useState(1)
  const totalAmount = tabDetailData?.orders?.reduce(
    (prev: number, next: IOrderDetail) => {
      return prev + next.subTotal + next.tax
    },
    0
  )
  const restaurantData = useSelector(restuarantData)

  const splitData = useSelector(splitDetail)
  const dispatch = useDispatch()
  useEffect(() => {
    if (splitData?.noOfGuest) {
      setNoOfGuest(splitData.noOfGuest)
    }
  }, [splitData])

  const totalPaidCashAmount = tabDetailData?.tabPayments?.payments
    ?.filter((payment) => payment.paymentMethodId === 6)
    .reduce((acc, payment) => {
      return acc + (payment.total - payment.totalTip)
    }, 0)

  const totalPaidSubTotal = tabDetailData?.tabPayments?.paid
  const totalPaidTax = tabDetailData?.tabPayments?.payments?.reduce(
    (acc, payment) => {
      return acc + payment.totalTax
    },
    0
  )
  const totalPaidFastabSum =
    totalPaidSubTotal + totalPaidTax - totalPaidCashAmount
  const noOfPaidGuest = tabDetailData?.noOfSplits
  const splitedAmount =
    (totalAmount - (totalPaidCashAmount || 0) - (totalPaidFastabSum || 0)) /
    (noOfGuest - (noOfPaidGuest || 0))

  const completedNoOfPayments = tabDetailData?.tabPayments?.totalPayments
  const totalToPay = splitedAmount * noOfPayingGuest

  return (
    <>
      <Dialog
        className="primary-dialog"
        onClose={onClose}
        maxWidth="xs"
        fullWidth
        open={open}
      >
        <DialogTitle>
          <h5>Split by Guest</h5>
          <Button
            variant="text"
            title="Close"
            onClick={onClose}
            color="inherit"
          >
            <img src={Close} alt="Close" />
          </Button>
        </DialogTitle>
        <DialogContent>
          <div className="split-by-guest-dialog">
            <div>
              <span>
                Divide{' '}
                <span style={{ color: '#59b3f6', fontWeight: 'bold' }}>
                  ${totalAmount?.toFixed(2)}
                </span>{' '}
                equally
              </span>
            </div>
            <div>
              <DonutChart
                key={noOfPayingGuest * noOfGuest}
                x={completedNoOfPayments > 0 ? totalPaidFastabSum : 0}
                y={totalToPay}
                z={
                  tabDetailData?.tabPayments?.remaining > 0
                    ? splitedAmount *
                      (noOfGuest - (noOfPaidGuest || 0) - noOfPayingGuest)
                    : totalAmount -
                      (totalPaidCashAmount || 0) -
                      splitedAmount * ((noOfPaidGuest || 0) + noOfPayingGuest)
                }
              />
            </div>

            <div className="split-by-guest">
              <div>
                {!(
                  totalPaidSubTotal &&
                  (!tabDetailData?.tabPayments?.isPosPaid || noOfPaidGuest > 0)
                ) && (
                  <>
                    <span>How many people are splitting?</span>
                    <Counter
                      setNoOfGuest={setNoOfGuest}
                      noOfGuest={noOfGuest}
                      maxNoOfGuest={restaurantData.maxNoOfGuest}
                    />
                  </>
                )}
              </div>
              {noOfGuest - (noOfPaidGuest || 0) >= 1 && (
                <>
                  <div className="separator" />
                  <span>How many are you paying for?</span>
                  <Counter
                    setNoOfGuest={setNoOfPayingGuest}
                    noOfGuest={noOfPayingGuest}
                    maxNoOfGuest={noOfGuest - (noOfPaidGuest || 0)}
                  />
                </>
              )}
              <div className="separator" />
              <ul>
                <li>
                  <span>Total Bill (Inclusive Tax)</span>
                  <span>$ {totalAmount?.toFixed(2)}</span>
                </li>
                <li>
                  <strong>You’re Paying</strong>
                  <strong>
                    ${(splitedAmount * noOfPayingGuest).toFixed(2)}
                  </strong>
                </li>
              </ul>
              <Button
                color="primary"
                linkState={{
                  isSplitByGuest: true,
                  totalAmount,
                  splitAmount: splitedAmount,
                }}
                title="Pay Your Tab"
                variant="contained"
                onClick={(e) => {
                  onClose(e)
                  dispatch(
                    setSplitDetail({
                      splitType: SplitType.Guest,
                      isSplitDone: true,
                      noOfGuest,
                      noOfPayingGuest,
                    })
                  )
                  closeCallback(SplitType.Guest, noOfGuest, noOfPayingGuest)
                }}
              >
                <span>
                  Pay Your Tab<small>|</small>
                  <strong>
                    ${(splitedAmount * noOfPayingGuest).toFixed(2)}
                  </strong>
                </span>
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default SplitByGuestDialog
