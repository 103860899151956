import { ApexOptions } from 'apexcharts'
import ReactApexChart from 'react-apexcharts'

const DonutChart = ({ x, y, z }) => {
  let series = [x, y, z]
  const options: ApexOptions = {
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: '100%',
            height: 300,
          },
          legend: {
            position: 'right',
            horizontalAlign: 'center',

            offsetY: 0,
            itemMargin: {
              vertical: 10,
            },
          },
        },
      },
    ],
    labels: ['Paid', 'Paying', 'Unpaid'],
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      pie: {
        donut: {
          size: '90%',
          labels: {
            show: true,
            name: {
              show: true,
              fontSize: '14px',
              offsetY: 0,
            },
            total: {
              show: true,
              label: 'Paying',
              fontSize: '14px',
              fontWeight: 'bold',
              color: '#59B3F6',
              formatter: () => `$${y.toFixed(2)}`,
            },
            value: {
              show: true,
              fontSize: '16px',
              fontWeight: 'bold',
              offsetY: 0,
              color: '#59B3F6',
              formatter: (val) => `$${Number(val)?.toFixed(2)}`,
            },
          },
        },
        expandOnClick: false,
      },
    },
    states: {
      hover: {
        filter: {
          type: 'none',
        },
      },
      active: {
        filter: {
          type: 'none',
        },
      },
    },
    legend: {
      show: true,
      position: 'right',
      horizontalAlign: 'center',
      offsetY: 0,
      itemMargin: {
        vertical: 10,
      },
    },
    tooltip: {
      enabled: false,
    },
    colors: ['#23992b', '#59B3F6', '#A6A6A7'],
  }

  if (x === 0 && y === 0 && z === 0) {
    series = [100]
    options.colors = ['#cccccc']
    options.dataLabels.enabled = false
    options.plotOptions.pie.donut.labels.show = false
  }

  return (
    <div
      className="app"
      style={{
        maxWidth: '100%',
        height: 'auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <div
        className="donut"
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <ReactApexChart
          options={options}
          series={series}
          type="donut"
          height={150}
        />
      </div>
    </div>
  )
}
export default DonutChart
