import { AxiosResponse } from 'axios'
import { IApiSuccessResponse } from 'interfaces/api-success-response'
import {
  IGetCampaignRequest,
  IGetCampaignResponse,
} from 'interfaces/guestMarketing'
import httpClient from './base-service'

const endPointBaseURL = `/GuestMarketing`

const getCampaignInvite = async (
  requestBody: IGetCampaignRequest
): Promise<AxiosResponse<IApiSuccessResponse<IGetCampaignResponse>>> =>
  httpClient.post<IApiSuccessResponse<IGetCampaignResponse>>(
    `${endPointBaseURL}/get-campaign-invite-by-id`,
    requestBody
  )

const updateCampaignInvite = async (
  requestBody: IGetCampaignRequest
): Promise<AxiosResponse<IApiSuccessResponse<string>>> =>
  httpClient.post<IApiSuccessResponse<string>>(
    `${endPointBaseURL}/update-campaign-invite`,
    requestBody
  )

export default {
  getCampaignInvite,
  updateCampaignInvite,
}
