/* eslint-disable no-lonely-if */
import { Dialog, DialogContent, DialogTitle } from '@mui/material'
import { Close, FullBill, SplitByGuest } from 'assets/images'
import AllSplitDialog from 'components/AllSplitDialog/Index'
import Button from 'components/Button/Index'
import SplitByGuestDialog from 'components/SplitByGuestDialog/Index'
import { SplitType } from 'enums/split-type'
import { setPaymentInProcess } from 'features/paymentProgress/paymentProgress'
import { rawTabDetailData, tabDetail } from 'features/tab/tab'
import { ITabDetail } from 'interfaces/tab'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import tabService from 'services/tab.service'
import {
  handleDemoPaymentClick,
  handlePaymentClick,
} from 'utility/doPaymentClick'

interface dialogProps {
  open: boolean
  onClose?: React.MouseEventHandler<Element>
  closeCallback?: (event: SplitType, noOfGuest?: number) => void
  demoTabData?: ITabDetail
  isDemoTabPage?: boolean
  isFromChange?: boolean
  testCallBack?: (e) => Promise<void>
  trackPaymentModeCallBack?: (selectedSplitType: number) => Promise<void>
}

function SplitBillDialog(props: dialogProps) {
  const {
    open,
    onClose,
    closeCallback,
    isDemoTabPage,
    demoTabData,
    isFromChange,
    testCallBack,
    trackPaymentModeCallBack,
  } = props
  const [openSplitDialog, setOpenSplitDialog] = useState(false)
  const closeSplitDialog = () => {
    setOpenSplitDialog(false)
  }
  const closeAllplitDialog = () => {
    setOpenSplitDialog(false)
    onClose(null)
  }
  const [openSplitByGuestDialog, setOpenSplitByGuestDialog] = useState(false)
  const closeSplitByGuestDialog = () => {
    setOpenSplitByGuestDialog(false)
  }
  const dispatch = useDispatch()
  const rawTabsDeatil = useSelector(rawTabDetailData)
  const tabDetails = useSelector(tabDetail)
  const navigate = useNavigate()

  return (
    <>
      <Dialog
        className="primary-dialog"
        onClose={onClose}
        maxWidth="xs"
        fullWidth
        open={open}
      >
        <DialogTitle>
          <h5>Split the bill</h5>
          <Button
            variant="text"
            title="Close"
            onClick={onClose}
            color="inherit"
          >
            <img src={Close} alt="Close" />
          </Button>
        </DialogTitle>
        <DialogContent className="pb-0">
          <div className="split-options">
            <Button
              variant="outlined"
              color="primary"
              onClick={async (e) => {
                closeCallback(SplitType.Full, 0)
                trackPaymentModeCallBack(SplitType.Full)
                if (isDemoTabPage === true) {
                  handleDemoPaymentClick(dispatch, navigate, demoTabData)
                } else {
                  if (tabDetails?.tabPayments?.totalPayments > 0) {
                    dispatch(setPaymentInProcess(true))
                    await tabService.setSplitData(
                      SplitType.Full,
                      tabDetails?.noOfGuest || 0
                    )
                    testCallBack(e)
                    dispatch(setPaymentInProcess(false))
                  } else {
                    if (isFromChange) {
                      handlePaymentClick({
                        dispatch,
                        navigate,
                        rawTabsDeatil,
                        tabDetails,
                        isFromChange,
                      })
                    } else {
                      testCallBack(e)
                    }
                  }
                }
              }}
              linkState={{
                isFullBill: true,
              }}
              title="Pay Full Tab"
            >
              <span>
                {tabDetails?.tabPayments?.totalPayments > 0
                  ? 'Pay The Rest'
                  : 'Pay Full Tab'}
              </span>
              <span className="icon-wrapper">
                <img src={FullBill} alt="Bill" />
              </span>
            </Button>
            <Button
              variant="outlined"
              color="primary"
              title="Split the Tab"
              onClick={() => {
                closeCallback(SplitType.Guest)
                setOpenSplitByGuestDialog(true)
                trackPaymentModeCallBack(SplitType.Guest)
              }}
            >
              <span>Split the Tab</span>
              <span className="icon-wrapper">
                <img src={SplitByGuest} alt="Guest" />
              </span>
            </Button>
          </div>
        </DialogContent>
      </Dialog>
      <SplitByGuestDialog
        open={openSplitByGuestDialog}
        onClose={closeSplitByGuestDialog}
        closeCallback={closeCallback}
      />
      <AllSplitDialog
        closeCallback={(e, noOfGuest) => {
          closeCallback(e, noOfGuest)
          closeSplitDialog()
        }}
        open={openSplitDialog}
        onClose={closeSplitDialog}
        onCloseAllDisalog={closeAllplitDialog}
      />
    </>
  )
}

export default SplitBillDialog
