import { Minus, Plus } from 'assets/images'
import React, { useState } from 'react'

interface dialogProps {
  setNoOfGuest: React.Dispatch<React.SetStateAction<number>>
  noOfGuest?: number
  maxNoOfGuest: number
}

function Counter(props: dialogProps) {
  const { setNoOfGuest, noOfGuest, maxNoOfGuest } = props
  const [count, setCount] = useState(noOfGuest)
  let currentCount = count
  setNoOfGuest(currentCount)

  if (count < 1) {
    currentCount = 1
    setNoOfGuest(1)
  } else if (count > maxNoOfGuest) {
    currentCount = maxNoOfGuest
    setNoOfGuest(maxNoOfGuest)
  }
  return (
    <>
      <div className="counter">
        <button
          type="button"
          title="Decrement"
          onClick={() => {
            setCount((currentCount -= 1))
          }}
        >
          <img src={Minus} alt="Minus" />
        </button>
        <span>{currentCount}</span>
        <button
          type="button"
          title="Increment"
          onClick={() => {
            setCount((prevCount) => Math.min(maxNoOfGuest, prevCount + 1))
          }}
        >
          <img src={Plus} alt="Plus" />
        </button>
      </div>
    </>
  )
}

export default Counter
