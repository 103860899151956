/* eslint-disable no-cond-assign */
/* eslint-disable no-constant-condition */
import * as signalR from '@microsoft/signalr'
import { setIsPaymentEnable } from 'features/restaurant/restaurant'
import {
  setPosPay,
  setRawTabDetail,
  setSplitDetail,
  setTabDetail,
  setTabItems,
  setTabSeats,
  setUpdatedItems,
} from 'features/tab/tab'
import { ISplitData, ITabDetail, ITabItem, ITableService } from 'interfaces/tab'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import fastabService from 'services/fastab.service'
import tabService from 'services/tab.service'
import trackingService from 'services/tracking.service'
import { store } from 'store'
import storageHelper from 'utility/browserStorageHelper'
import { itemsToSeat } from 'utility/helper'

const useSignalR = () => {
  const dispatch = useDispatch()
  const searchParameters = new URLSearchParams(
    window.location.search.split('?')[1]
  )
  const tableId =
    searchParameters.get('pId') || sessionStorage.getItem('posTableId')
  let status
  let tableEnableTableId
  const getIsPaymentEnable = async () => {
    let ser
    if (Number(sessionStorage.getItem('tid')) > 0) {
      const data = await tabService.getIsPaymentEnable({
        tableId: Number(sessionStorage.getItem('tid')),
      })
      dispatch(setIsPaymentEnable(data.data.data))
      status = data.data.data.status
      tableEnableTableId = data.data.data.tableId
      ser = data.data.data
    }
    return ser
  }
  useEffect(() => {
    const hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(`${process.env.REACT_APP_API_SERVER}/notify-client-connect`)
      .configureLogging(signalR.LogLevel.Information)
      .withAutomaticReconnect()
      .build()
    const stopConnection = () => {
      hubConnection.stop()
    }
    if (!tableId) {
      return stopConnection
    }

    // Starts the SignalR connection
    hubConnection.start().then(async () => {
      const paymentStatus = await getIsPaymentEnable()

      // Once started, invokes the sendConnectionId in our ChatHub inside our ASP.NET Core application.
      if (hubConnection.connectionId && paymentStatus?.status) {
        hubConnection.invoke('getconnectionid').then(async (d) => {
          // eslint-disable-next-line no-console
          const data = await fastabService.createFastab({
            isActive: true,
            socketId: d,
            posTableId: Number(tableId),
            socketGuid: sessionStorage.getItem('connectionGuid'),
            loginId: Number(localStorage.getItem('loginId')),
            tableId: Number(sessionStorage.getItem('tid')),
          })
          const guid = data.data.data.socketGuid
          sessionStorage.setItem('connectionGuid', guid)
          sessionStorage.setItem('connectionid', d)
          sessionStorage.setItem(
            'tabConnectionId',
            data.data.data.tabConnectionId.toString()
          )
          sessionStorage.setItem('tabId', data.data.data.tabId.toString())

          const interval = setInterval(() => {
            const restaurantId = storageHelper.getRestaurantId()
            if (restaurantId > 0) {
              trackingService.trackScan()
              clearInterval(interval)
            }
          }, 500)

          console.log(d)
        })
      }
    })

    hubConnection.on(
      'broadcastyourtabdata',
      async (response: ITabDetail | ISplitData | string | ITableService) => {
        console.log('signal', response)
        if ((response as string) === 'table closed') {
          dispatch(
            setSplitDetail({
              splitType: null,
              isSplitDone: false,
              noOfGuest: 0,
            })
          )
          dispatch(
            setIsPaymentEnable({
              // eslint-disable-next-line no-restricted-globals
              status,
              isTableDisable: false,
            })
          )
          const userDestination = window.location.pathname
          sessionStorage.setItem('tabId', 'closed')
          if (userDestination === '/your-tab' || userDestination === '/') {
            dispatch(setTabSeats([]))
            dispatch(setTabItems([]))
            dispatch(setTabDetail(null))
            dispatch(setRawTabDetail(null))
          }
          return
        }

        if (
          (response as ITableService).isTableOutOfService ||
          (response as ITableService).isTableOutOfService === false
        ) {
          dispatch(
            setIsPaymentEnable({
              // eslint-disable-next-line no-restricted-globals
              status,
              isTableDisable: (response as ITableService).isTableOutOfService,
              tableId: tableEnableTableId,
            })
          )
          return
        }
        if (
          (response as ITabDetail).orders &&
          (response as ITabDetail).orders.length > 0
        ) {
          const tabDetailed = response as ITabDetail
          const userDestination = window.location.pathname
          if (
            tabDetailed?.tabPayments?.isPosPaid === true &&
            userDestination === '/payment'
          ) {
            dispatch(setPosPay(true))
            tabDetailed.orders.forEach((element) => {
              element.orderDate = null
            })
            // const rawData = JSON.stringify(tabDetailed)
            // dispatch(setRawTabDetail(rawData))
            sessionStorage.setItem('tabId', tabDetailed.tabId.toString())
            tabDetailed.orders.forEach((element) => {
              element.orderDate = null
              element.isSelected = true
              element.isPaid = false
            })
            // dispatch(setTabDetail(tabDetailed))
            if (tabDetailed.orders?.length > 0) {
              let allItems: ITabItem[] = []
              tabDetailed.orders.forEach((element) => {
                allItems = [...allItems, ...element.items]
              })
              if (allItems?.length > 0) {
                dispatch(setTabSeats(itemsToSeat(allItems)))
                dispatch(setTabItems(allItems))
              }
            }
          } else if (
            userDestination === '/your-tab' ||
            userDestination === '/'
          ) {
            tabDetailed.orders.forEach((element) => {
              element.orderDate = null
            })
            const rawData = JSON.stringify(tabDetailed)
            dispatch(setRawTabDetail(rawData))
            sessionStorage.setItem('tabId', tabDetailed.tabId.toString())
            // eslint-disable-next-line no-cond-assign
            const data = store.getState().tab?.tabDetailData
            tabDetailed?.orders?.forEach((element) => {
              element.orderDate = null
              if (data === null) {
                element.isSelected = true
              } else {
                const cOrder = data?.orders?.find(
                  (d) => d.orderId === element.orderId
                )
                if (cOrder) {
                  element.isSelected = cOrder.isSelected
                } else {
                  element.isSelected = true
                }
              }
              element.isPaid = false
            })
            dispatch(setTabDetail(tabDetailed))
            if (tabDetailed.paymentModeId) {
              if (
                tabDetailed?.orders?.length > 1 &&
                tabDetailed?.paymentModeId === 6
              ) {
                sessionStorage.setItem('paymentModeId', null)
                dispatch(
                  setSplitDetail({
                    splitType: null,
                    isSplitDone: true,
                    noOfGuest: null,
                  })
                )
              } else {
                sessionStorage.setItem(
                  'paymentModeId',
                  tabDetailed.paymentModeId.toString()
                )
                dispatch(
                  setSplitDetail({
                    splitType: tabDetailed.paymentModeId,
                    isSplitDone: true,
                    noOfGuest: tabDetailed.noOfGuest,
                  })
                )
              }
            }
            if (tabDetailed.orders?.length > 0) {
              let allItems: ITabItem[] = []
              if (tabDetailed.tabStatus.toLowerCase() === 'changed') {
                tabDetailed.orders.forEach((element) => {
                  allItems = [...allItems, ...element.items]
                })
                dispatch(setUpdatedItems(allItems))
              } else {
                tabDetailed.orders.forEach((element) => {
                  allItems = [...allItems, ...element.items]
                })
                if (allItems?.length > 0) {
                  dispatch(setTabSeats(itemsToSeat(allItems)))
                  dispatch(setTabItems(allItems))
                }
              }
            }
          }
          // end
        } else if ((response as ISplitData).splitType) {
          const userDestination = window.location.pathname
          if (userDestination !== '/payment') {
            const splitData = response as ISplitData
            dispatch(
              setSplitDetail({
                splitType: splitData.splitType,
                isSplitDone: true,
                noOfGuest: splitData.noOfGuest,
                noOfPayingGuest: localStorage.getItem('noOfPayingGuest'),
              })
            )
          }
        }
      }
    )
    return stopConnection
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

export default useSignalR
