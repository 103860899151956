/* eslint-disable jsx-a11y/iframe-has-title */
import { Dialog, DialogContent, DialogTitle } from '@mui/material'
import { Close } from 'assets/images'
import Button from 'components/Button/Index'
import React from 'react'

interface dialogProps {
  open: boolean
  onClose?: React.MouseEventHandler<Element>
  url?: string
}

function FaqDialog(props: dialogProps) {
  const { open, onClose, url } = props

  const onDialogClose = (e: React.MouseEvent<HTMLElement>) => {
    onClose(e)
  }

  return (
    <>
      <Dialog
        className="primary-dialog alert-dialog faq-dialog"
        maxWidth="xl"
        fullWidth
        open={open}
        onClick={(e: React.MouseEvent<HTMLElement>) => onDialogClose(e)}
      >
        <DialogTitle>
          <Button
            variant="text"
            title="Close"
            onClick={(e: React.MouseEvent<HTMLElement>) => onDialogClose(e)}
            className="icon-btn rounded ml-auto"
            color="inherit"
          >
            <img src={Close} alt="Close" />
          </Button>
        </DialogTitle>
        <DialogContent className="pb-0">
          <iframe src={url} width="100%" height="100%" />
        </DialogContent>
      </Dialog>
    </>
  )
}

export default FaqDialog
