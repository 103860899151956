import { ThemeProvider } from '@emotion/react'
import { createTheme } from '@mui/material'
import Loader from 'components/Loader/Index'
import TwoFaVerification from 'components/TwoFactor/Index'
import { setMenus } from 'features/menu/menu'
import { setMenuInProgress } from 'features/menuProgressState/menuProgressState'
import {
  setIsPaymentEnable,
  setPosConfig,
  setRestaurant,
} from 'features/restaurant/restaurant'
import { setTab } from 'features/tab/tab'
import useSignalR from 'hooks/useSignalR'
import AccountCreated from 'pages/AccountCreated/Index'
import ChangePassword from 'pages/ChangePassword/Index'
import CreateAccount from 'pages/CreateAccount/Index'
import CreatePassword from 'pages/CreatePassword/Index'
import EmailReceiptSent from 'pages/EmailReceiptSent/Index'
import Error from 'pages/Error/Index'
import ForgotPassword from 'pages/ForgotPassword/Index'
import GuestReceipt from 'pages/GuestReceipt/Index'
import Home from 'pages/Home/Index'
// import Landing from 'pages/Landing/Index'
import PrivateRoute from 'PrivateRoutes'
import Login from 'pages/Login/Index'
import MainMenu from 'pages/MainMenu/Index'
import MyProfile from 'pages/MyProfile/Index'
import OtpVerification from 'pages/OtpVerification/Index'
import PasswordChanged from 'pages/PasswordChanged/Index'
import PaymentContainer from 'pages/Payment/PaymentContainer'
import PaymentSuccessful from 'pages/PaymentSuccessful/Index'
import ScrollToTop from 'pages/ScrollToTop/Index'
import ThanksMessage from 'pages/ThanksMessage/Index'
import VerifyEmail from 'pages/VerifyEmail/Index'
import ViewMenu from 'pages/ViewMenu/Index'
import YourTab from 'pages/YourTab/Index'
import { useCallback, useEffect, useState } from 'react'
import { isFirefox } from 'react-device-detect'
import { useDispatch } from 'react-redux'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import menuService from 'services/menu.service'
import paymentService from 'services/payment.service'
import restaurantService from 'services/restaurant.service'
import tabService from 'services/tab.service'
import Landing from 'pages/Landing/Index'
import guestService from 'services/guest.service'
import SubscriptionContainer from 'pages/Subscription/SubscriptionContainer'
import ThankYou from 'pages/Subscription/ThankYou'
import Redeem from 'pages/GuestMarketing/Redeem'
import GuestUnsubscribe from 'pages/GuestUnsubscribe/Index'
import ContactForm from 'pages/GuestMarketing/Form'

if (isFirefox) {
  document.body.classList.add('browser-firefox')
}
function App() {
  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 576,
        md: 768,
        lg: 992,
        xl: 1200,
      },
    },
    typography: {
      fontFamily: ['Poppins', 'sans-serif'].join(','),
    },
    spacing: [0, 6, 12, 18],
  })
  useSignalR()
  let paymentEnableData

  if (localStorage.getItem('faqUrl')) {
    localStorage.removeItem('faqUrl')
  }
  const dispatch = useDispatch()
  const searchParameters = new URLSearchParams(
    window.location.search.split('?')[1]
  )
  const tableId = searchParameters.get('pId')
    ? searchParameters.get('pId')
    : sessionStorage.getItem('posTableId')
  const establishmentId = searchParameters.get('eId')
    ? searchParameters.get('eId')
    : sessionStorage.getItem('establishmentId')
  const tid = searchParameters.get('tid')
    ? searchParameters.get('tid')
    : sessionStorage.getItem('tid')
  const tableName = searchParameters.get('tn')
    ? searchParameters.get('tn').replace('%', '')
    : sessionStorage.getItem('tn')
  const isDemoTabPage = searchParameters.get('isDemoTab')
    ? searchParameters.get('isDemoTab')
    : sessionStorage.getItem('isDemoTabPage')
  // const isLoggedIn = localStorage.getItem('loginAuthToken')
  if (isDemoTabPage) {
    sessionStorage.setItem('isDemoTabPage', 'true')
  }
  const getRestaurantDetail = useCallback(async (id: number) => {
    if (!id) {
      return
    }
    const data = await restaurantService.getResturantById({
      establishmentId: id,
    })
    const restaurantDetailData = data.data.data
    dispatch(setRestaurant(restaurantDetailData))
    if (restaurantDetailData.restaurantId) {
      const posConfigData = await restaurantService.getPosDataByEstablishmentId(
        {
          establishmentId: id,
        }
      )
      dispatch(setPosConfig(posConfigData.data.data))
    } else {
      dispatch(
        setIsPaymentEnable({
          status: paymentEnableData ? paymentEnableData.status : true,
          isTableDisable: true,
        })
      )
    }

    // storing the restaurant id in local storage to use it later in tracking event
    sessionStorage.setItem(
      'restaurantId',
      restaurantDetailData.restaurantId?.toString()
    )

    // calling tracking API for Scan Complete event
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const [stripeAPIKey, setStripeAPIKey] = useState('')
  const [isOrderAvailable, setOrderAvailable] = useState(false)
  const loadStripePromise = async () => {
    if (stripeAPIKey) {
      return stripeAPIKey
    }
    const response = await paymentService.getStripePublishableKey()
    const apiKey = response?.data?.data?.publishableKey
    setStripeAPIKey(apiKey)
    return apiKey
  }
  loadStripePromise()

  const getAPIKey = () => {
    return stripeAPIKey
  }

  const getIsPaymentEnable = async () => {
    if (Number(sessionStorage.getItem('tid')) > 0) {
      const data = await tabService.getIsPaymentEnable({
        tableId: Number(sessionStorage.getItem('tid')),
      })
      console.log(data.data.data)
      paymentEnableData = data.data.data
      dispatch(setIsPaymentEnable(data.data.data))
    }
  }

  useEffect(() => {
    dispatch(
      setTab({
        establishmentId: Number(establishmentId),
        tableId: Number(tableId),
        tableName,
      })
    )
    if (establishmentId) {
      sessionStorage.setItem('establishmentId', establishmentId?.toString())
    }
    if (tableId) {
      sessionStorage.setItem('posTableId', tableId?.toString())
    }

    if (tid && tableName) {
      sessionStorage.setItem('tid', tid)
      sessionStorage.setItem('tn', tableName)
    }
    getIsPaymentEnable()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [establishmentId, tableId, tableName, tid])

  const getMenuLists = useCallback(
    async (id) => {
      try {
        dispatch(setMenuInProgress(true))
        const data = await menuService.getAllMenu({
          establishmentId: id,
        })
        dispatch(
          setMenus({
            menuList: data.data.data.sort((a, b) => {
              return a.position - b.position
            }),
          })
        )
        dispatch(setMenuInProgress(false))
      } catch (e) {
        dispatch(setMenuInProgress(false))
        console.log(e)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  useEffect(() => {
    if (establishmentId != null) {
      getRestaurantDetail(+establishmentId)
      getMenuLists(establishmentId)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [establishmentId])

  const CheckIsOrderAvailable = async () => {
    const data = await tabService.CheckAnyOrderAvailable({
      tableId: Number(tid),
    })
    setOrderAvailable(data.data.data)
  }
  useEffect(() => {
    CheckIsOrderAvailable()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  window.onbeforeunload = () => {
    if (localStorage.getItem('IsPaymentProgress') === 'true') {
      guestService.addLog({
        message: `Payment Is Progress TabId: ${sessionStorage.getItem(
          'tabId'
        )}`,
      })
    }
  }
  const GetLandingOrViewPage = () => {
    return isOrderAvailable ? <YourTab /> : <Landing />
  }
  return (
    <ThemeProvider theme={theme}>
      {/* basename="/design/fastab" */}
      <BrowserRouter>
        <ScrollToTop />
        {/* Loader */}
        <Loader />
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Routes>
          <Route
            path="/"
            element={tableName ? GetLandingOrViewPage() : <Home />}
          />
          <Route path="/view-menu" element={<ViewMenu />} />
          <Route path="/your-tab" element={<YourTab />} />
          <Route path="/two-factor" element={<TwoFaVerification />} />
          <Route
            path="/payment"
            element={<PaymentContainer callBackGetAPIKey={getAPIKey} />}
          />
          <Route path="/payment-successful" element={<PaymentSuccessful />} />
          <Route path="/email-receipt" element={<EmailReceiptSent />} />
          <Route path="/thanks-message" element={<ThanksMessage />} />
          <Route
            path="/create-account"
            element={
              <PrivateRoute Component={CreateAccount} isNotAllowedAfterLogin />
            }
          />
          <Route path="/account-created" element={<AccountCreated />} />
          <Route path="/verify-email" element={<VerifyEmail />} />
          <Route path="/login" element={<Login />} />
          <Route
            path="/forgot-password"
            element={
              <PrivateRoute Component={ForgotPassword} isNotAllowedAfterLogin />
            }
          />
          <Route path="/otp-verification" element={<OtpVerification />} />
          <Route path="/create-password" element={<CreatePassword />} />
          <Route
            path="/guest-receipt"
            element={
              <PrivateRoute Component={GuestReceipt} isNotAllowedBeforeLogin />
            }
          />
          <Route
            path="/change-password"
            element={
              <PrivateRoute
                Component={ChangePassword}
                isNotAllowedBeforeLogin
              />
            }
          />
          <Route path="/password-changed" element={<PasswordChanged />} />
          <Route
            path="/my-profile"
            element={
              <PrivateRoute Component={MyProfile} isNotAllowedBeforeLogin />
            }
          />
          <Route path="/main-menu" element={<MainMenu />} />
          <Route path="/home" element={<Home />} />
          <Route path="*" element={<Error />} />
          <Route
            path="/subscription"
            element={<SubscriptionContainer callBackGetAPIKey={getAPIKey} />}
          />
          <Route path="/subscription-thank-you" element={<ThankYou />} />
          <Route
            path="/unsubscribe/:campaignId/:restaurantId/:email"
            element={<GuestUnsubscribe />}
          />
          <Route path="/redeem" element={<Redeem />} />
          <Route path="/contact-form/:formId" element={<ContactForm />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  )
}

export default App
