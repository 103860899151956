import { createSlice } from '@reduxjs/toolkit'
import { ISeat, ISplitDetail, ITab, ITabDetail, ITabItem } from 'interfaces/tab'

const tabSlice = createSlice({
  name: 'tab',
  initialState: {
    tabData: {},
    tabDetailData: null,
    rawTabDetailData: null,
    tabItems: [],
    seats: [],
    splitDetail: {
      isSplitDone: false,
      splitType: null,
      noOfPayingGuest: 1,
    },
    isPartialPaidFromPos: false,
  },
  reducers: {
    setTab: (state, action) => {
      state.tabData = action.payload
    },
    setTabDetail: (state, action) => {
      state.tabDetailData = action.payload
    },
    setRawTabDetail: (state, action) => {
      state.rawTabDetailData = action.payload
    },
    setTabItems: (state, action) => {
      const items: ITabItem[] = action.payload
      const newData = items.map((item) => {
        return { ...item, isSelected: false }
      })
      state.tabItems = newData
    },
    setPosPay: (state, action) => {
      const newData: boolean = action.payload
      state.isPartialPaidFromPos = newData
    },
    updateItems: (state, action) => {
      const { item, isChecked } = action.payload
      const itemIndex = state.tabItems.findIndex(
        (d) => d.posItemId === item.posItemId && d.itemId === item.itemId
      )
      state.tabItems[itemIndex] = { ...item, isSelected: isChecked }
      state.tabItems = [...state.tabItems]
    },
    updateOrderItems: (state, action) => {
      const { orderId, isChecked } = action.payload
      if (orderId === null) {
        state?.tabDetailData?.orders?.forEach((ele) => {
          ele.items.forEach((element) => {
            element.isSelected = false
            state.tabItems.forEach((index) => {
              if (index.itemId === element.itemId) {
                index.isSelected = false
              }
            })
          })
        })
      } else {
        const itemIndex = state.tabDetailData.orders.findIndex(
          (d) => d.orderId === orderId
        )
        state.tabDetailData.orders[itemIndex].isSelected = isChecked
        state.tabDetailData.orders[itemIndex].items.forEach((element) => {
          element.isSelected = isChecked
          state.tabItems.forEach((index) => {
            if (index.itemId === element.itemId) {
              index.isSelected = isChecked
            }
          })
        })
      }
      state.tabDetailData.orders = [...state.tabDetailData.orders]
    },
    setTabSeats: (state, action) => {
      state.seats = action.payload
    },
    updateSeats: (state, action) => {
      const { seat, isChecked } = action.payload
      const seatIndex = state.seats.findIndex(
        (d) => d.seatNumber === seat.seatNumber
      )
      state.seats[seatIndex] = { ...seat, isSelected: isChecked }
      state.seats = [...state.seats]
    },
    setSplitDetail: (state, action) => {
      localStorage.setItem(
        'noOfPayingGuest',
        action.payload.noOfPayingGuest ?? 0
      )
      state.splitDetail = action.payload
    },
    setUpdatedItems: (state, action) => {
      state.tabItems.forEach((updateItem) => {
        action.payload.forEach((currentItem) => {
          if (currentItem.itemId === updateItem.itemId) {
            if (currentItem.isPaid) {
              updateItem.isSelected = false
            }
          }
        })
      })
      // const deletedItems = state?.tabItems?.filter(
      //   (d) => !action.payload.map((x) => x.itemId).includes(d.itemId)
      // )
      // deletedItems?.forEach((item) => {
      //   const itemIndex = action.payload?.findIndex(
      //     (d) => d.itemId === item.itemId
      //   )
      //   action.payload?.splice(itemIndex, 1)
      // })
      state.tabItems = [...action.payload]
    },
  },
})

export const {
  setTab,
  setTabDetail,
  setTabItems,
  updateItems,
  setSplitDetail,
  setTabSeats,
  updateSeats,
  setPosPay,
  updateOrderItems,
  setRawTabDetail,
  setUpdatedItems,
} = tabSlice.actions

export default tabSlice.reducer

export const tabData = (state: { tab: { tabData: ITab } }) => state.tab.tabData
export const tabDetail = (state: { tab: { tabDetailData: ITabDetail } }) =>
  state.tab.tabDetailData
export const rawTabDetailData = (state: {
  tab: { rawTabDetailData: string }
}) => state.tab.rawTabDetailData
export const tabItems = (state: { tab: { tabItems: ITabItem[] } }) =>
  state.tab.tabItems
export const seats = (state: { tab: { seats: ISeat[] } }) => state.tab.seats
export const splitDetail = (state: { tab: { splitDetail: ISplitDetail } }) =>
  state.tab.splitDetail
export const isPartialPaidFromPos = (state: {
  tab: { isPartialPaidFromPos: boolean }
}) => state.tab.isPartialPaidFromPos
