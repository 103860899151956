import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useParams } from 'react-router-dom'
import guestService from 'services/guest.service'

function ContactForm() {
  const { formId } = useParams()
  const [template, setTemplate] = useState<string>(null)

  const getForm = async () => {
    try {
      const response = await guestService.ContactForm({
        scriptId: formId,
      })
      setTemplate(response.data.data)
    } catch (error) {
      console.log('error', error)
    }
  }

  useEffect(() => {
    getForm()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formId])

  return (
    <>
      <Helmet>
        <title>Contact Form | FasTab</title>
      </Helmet>
      {/* wrapper start */}
      <section style={{ height: '900px', paddingTop: '5%' }}>
        {/* main-content start */}
        {template && (
          <iframe
            srcDoc={template}
            title="Custom HTML Content"
            width="100%"
            height="100%"
          />
        )}
        {/* main-content end */}
      </section>
      {/* wrapper end */}
    </>
  )
}

export default ContactForm
